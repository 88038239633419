import React, { useState, useCallback, useEffect } from 'react';
import { JsonParam, useQueryParam } from 'use-query-params';
import { navigate } from 'gatsby';

import { LOCAL_STORAGE } from 'consts';
import { getStorageObject, setStorageObject, clearStorage } from 'utils/localStorage';
import { getProfile } from 'views/ProfilePage/api';
import { tryGetFirstError } from 'utils/requests';
import notify from 'notify';
import { UserViewType } from 'enums';
import {
  login as loginRequest,
  logout as logoutRequest,
  changeViewType as changeViewTypeRequest,
  LoginPayload
} from './authRequests';

const { AUTH_STORAGE_KEY, PROFILE_STORAGE_KEY, SAVED_ITEMS_STORAGE_KEY } = LOCAL_STORAGE;

interface AuthContextValues {
  authorized: boolean;
  authData?: AuthData;
  profile?: ProfileData;
  savedItems?: SavedItemsData;
  setAuthData: (value: AuthData) => void;
  setProfile: (value: ProfileData) => void;
  setSavedItems: (value: SavedItemsData) => void;
  login: (data: LoginPayload) => Promise<void>;
  loginManually: (authData: LoginData) => void;
  logout: () => Promise<void>;
  logoutManually: () => void;
  changeViewType: (value: UserViewType) => void;
}

const AuthContext = React.createContext({} as AuthContextValues);

export const AuthContextWrapper = ({ children }) => {
  const [authQueryParam, setAuthQueryParam] = useQueryParam('auth', JsonParam);

  const [authData, dispatchSetAuthData] = useState<AuthData | undefined>(
    getStorageObject(AUTH_STORAGE_KEY)
  );
  const [profile, dispatchSetProfile] = useState<ProfileData | undefined>(
    getStorageObject(PROFILE_STORAGE_KEY)
  );
  const [savedItems, dispatchSetSavedItems] = useState<SavedItemsData | undefined>(
    getStorageObject(SAVED_ITEMS_STORAGE_KEY)
  );
  const [authorized, setAuthorized] = useState(Boolean(authData));

  const setAuthData = (value: AuthData) => {
    setStorageObject(AUTH_STORAGE_KEY, value);
    dispatchSetAuthData(value);
  };

  const setProfile = (value: ProfileData) => {
    setStorageObject(PROFILE_STORAGE_KEY, value);
    dispatchSetProfile(value);
  };

  const setSavedItems = (value: SavedItemsData) => {
    setStorageObject(SAVED_ITEMS_STORAGE_KEY, value);
    dispatchSetSavedItems(value);
  };

  const setAuthDataStorage = useCallback((data: LoginData) => {
    setAuthData({ ...data.authData, admin: data.admin, teamLead: data.teamLead });
    setProfile(data.profile);
    // setSavedItems(data.savedItems);
  }, []);

  const login = useCallback(
    async data => {
      const response = await loginRequest(data);
      setAuthDataStorage(response);
      setAuthorized(true);
    },
    [setAuthDataStorage]
  );

  const logout = useCallback(async () => {
    try {
      await logoutRequest();
    } catch (err) {
      notify(tryGetFirstError(err));
    }
    clearStorage();
    setAuthorized(false);
  }, []);

  const changeViewType = useCallback(async (viewType: UserViewType) => {
    try {
      const onlyMeView = await changeViewTypeRequest(viewType);
      const profile = getStorageObject(PROFILE_STORAGE_KEY);
      setProfile({ ...profile, onlyMeView });
      window?.location?.reload();
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  }, []);

  const loginManually = (authData: LoginData) => {
    setAuthDataStorage(authData);
    setAuthorized(true);
  };

  const logoutManually = () => {
    clearStorage();
    setAuthorized(false);
  };

  const fetchProfile = useCallback(async () => {
    try {
      const profile = await getProfile();
      setProfile({
        avatar: profile.avatar,
        email: profile.email,
        name: profile.name,
        phone: profile.phone || undefined
      });
    } catch (err) {
      notify(tryGetFirstError(err));
    }
  }, []);

  useEffect(() => {
    if (authQueryParam) {
      setAuthData(authQueryParam);
      setAuthorized(true);
      setAuthQueryParam(undefined, 'replaceIn');
      fetchProfile();
      navigate('/');
    }
  }, [authQueryParam, fetchProfile, setAuthQueryParam]);

  const values: AuthContextValues = {
    authorized,
    authData,
    setAuthData,
    profile,
    setProfile,
    savedItems,
    setSavedItems,
    login,
    loginManually,
    logout,
    logoutManually,
    changeViewType
  };
  return <AuthContext.Provider value={values}>{children}</AuthContext.Provider>;
};

export default AuthContext;
