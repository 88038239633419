import { fetchWithToken } from 'utils/fetch';
import { createFormData, urlEncodeBody, checkSuccess } from 'utils/requests';
import { Transaction } from 'views/TransactionsPage/transactions.d';

export async function getProfile(): Promise<Profile> {
  const response = await fetchWithToken('/users/profile');
  const data = await response.json();

  return {
    id: data.id,
    active: Boolean(data.active),
    avatar: data.avatar,
    brokerMlsId: data.broker_mls_id,
    email: data.email,
    name: data.name || `${data.first_name} ${data.last_name}`.trim(),
    phone: data.phone,
    phoneOffice: data.office_phone,
    role: data.role,
    roleId: data.role_id,
    emailSignature: data.signature,
    stateLicenseId: data.state_license_id
  };
}

export async function getTransactions(): Promise<Transaction[]> {
  const response = await fetchWithToken('/users/profile/payments');
  const data = await response.json();

  checkSuccess(data);
  return data.map(item => ({
    amount: item.amount,
    createdOn: item.created_on,
    description: item.description,
    id: item.id,
    note: item.note,
    refundAmount: item.refund_amount,
    refundNote: item.refund_note,
    stripeCardId: item.stripe_card_id,
    stripePaymentId: item.stripe_payment_id
  }));
}

export async function updateProfile(
  value: Omit<Partial<Profile>, 'avatar'> & { avatar?: File; password?: string }
): Promise<Profile> {
  const body = createFormData({
    broker_mls_id: value.brokerMlsId,
    name: value.name,
    phone: value.phone,
    office_phone: value.phoneOffice,
    avatar: value.avatar,
    state_license_id: value.stateLicenseId
  });
  const response = await fetchWithToken('/users', { method: 'PATCH', body });
  const data = await response.json();

  return {
    id: data.id,
    active: Boolean(data.active),
    avatar: data.avatar,
    brokerMlsId: data.broker_mls_id,
    email: data.email,
    name: data.name || `${data.first_name} ${data.last_name}`.trim(),
    phone: data.phone,
    phoneOffice: data.office_phone,
    role: data.role,
    roleId: data.role_id
  };
}

export async function updatePassword(value: {
  password: string;
  passwordOld: string;
}): Promise<void> {
  const body = urlEncodeBody({
    password: value.password,
    old_password: value.passwordOld
  });
  await fetchWithToken('/users/password', { method: 'PATCH', body });
}

export const updateEmailSignature = async (value: string): Promise<string> => {
  const body = urlEncodeBody({
    signature: value
  });
  const response = await fetchWithToken('/users', { method: 'PATCH', body });
  const data = await response.json();

  checkSuccess(data);
  return data.signature;
};

export async function getPaymentCards(): Promise<Array<{ [key: string]: any }>> {
  const response = await fetchWithToken('/users/profile/payments/card');
  const data = await response.json();

  checkSuccess(data);

  return Object.values(data).map(mapPaymentCard);
}

export const addNewCard = async tokenId => {
  const body = urlEncodeBody({
    stripe_token: tokenId
  });
  const response = await fetchWithToken('/users/profile/payments/card', { method: 'POST', body });
  const data = await response.json();

  return mapPaymentCard(data);
};

export const setPrimaryCard = async cardId => {
  await fetchWithToken(`/users/profile/payments/card/${cardId}`, { method: 'PATCH' });
};

export const removeCard = async cardId => {
  await fetchWithToken(`/users/profile/payments/card/${cardId}`, { method: 'DELETE' });
};

const mapPaymentCard = item => ({
  id: item.id,
  brand: item.brand,
  exp_date: `${String(item.exp_month)}/${String(item.exp_year).slice(-2)}`,
  last4: item.last4,
  card_id: item.stripe_card_id,
  primary_card: Boolean(item.primary_card)
});
