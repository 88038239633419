import tryParseJSON from './tryParseJSON';

type ResponseBody = object | null | string;

const fetchJSON = async (url: string, options: RequestInit = {}) => {
  const response = await fetch(url, options);
  const body = getResponseBody(response);
  const responseWithBody = { response, body };
  return responseWithBody;
};

const getResponseBody = (response: Response): Promise<ResponseBody> => {
  const contentType = response.headers.get('content-type');
  return contentType === 'application/json'
    ? response
        .clone()
        .text()
        .then(tryParseJSON)
    : response.clone().text();
};

export default fetchJSON;
