const toCamel = s => {
  return s.replace(/([-_][a-z0-9])/gi, $1 => {
    return $1
      .toUpperCase()
      .replace('-', '')
      .replace('_', '');
  });
};

const keysToCamel = o => {
  if (o === Object(o) && !Array.isArray(o) && typeof o !== 'function') {
    const n = {};

    Object.keys(o).forEach(k => {
      n[toCamel(k)] = keysToCamel(o[k]);
    });

    return n;
  }
  if (Array.isArray(o)) {
    return o.map(i => {
      return keysToCamel(i);
    });
  }

  return o;
};

export default keysToCamel;
