import React from 'react';
import toaster from 'toasted-notes';
import { MessageOptionalOptions } from 'toasted-notes/lib/ToastManager';

import Notification from 'components/Notification';

export default function notify(content: React.ReactNode, settings?: MessageOptionalOptions) {
  toaster.notify(
    props => (
      <Notification {...props} type={settings?.type}>
        {content}
      </Notification>
    ),
    {
      duration: 3000,
      position: 'bottom',
      ...settings
    }
  );
}
