import React from 'react';
import { ThemeProvider, Global, css } from '@emotion/react';

import theme from './theme.json';

const GlobalStyle = props => (
  <Global
    {...props}
    styles={css`
      html,
      body,
      #___gatsby,
      #gatsby-focus-wrapper {
        height: 100%;
      }
      body {
        margin: 0;
        font-family: Inter, apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Helvetica, Arial,
          sans-serif, 'Apple Color Emoji', 'Segoe UI Emoji', 'Segoe UI Symbol';
        font-size: 12px;
      }

      hr {
        border-width: 0 0 1px 0;
      }

      .hide_temp{display:none !important;}

      #modal-root {
        width: 100%;
      }

      .Toaster {
        height: unset;

        .Toaster__manager-bottom {
          margin-bottom: 10px !important;
        }
      }
      .scrollbar-custom {
        scrollbar-width: thin;
        scrollbar-color: #cdcdcd #8b8b8b;
        &::-webkit-scrollbar {
          position: fixed;
          width: 4px;
          height: 4px;
          background: #cdcdcd;
          border-radius: 3px;
        }
        &::-webkit-scrollbar-thumb {
          background: #8b8b8b;
          border-radius: 3px;
        }
        &::-webkit-scrollbar-corner {
          background: #ffffff00;
        }
      }

      .scrollable-fct {
        scrollbar-width: thin;
        scrollbar-color: #cdcdcd #8b8b8b;
        &::-webkit-scrollbar {
          width: 5px;
          background: transparent;
        }
        &::-webkit-scrollbar-track {
          background: #cdcdcd;
          border: 2px solid transparent;
          background-clip: content-box;
        }
        &::-webkit-scrollbar-thumb {
          background: #cdcdcd;
          border: 1px solid #cdcdcd;
        }
      }

      .scrollable {
        scrollbar-width: thin;
        scrollbar-color: #cdcdcd #8b8b8b;
        &::-webkit-scrollbar {
          width: 5px;
          background: transparent;
        }
        &::-webkit-scrollbar-track {
          background: #cdcdcd;
          border: 5px solid #fff;
          background-clip: content-box;
        }
        &::-webkit-scrollbar-thumb {
          background: #cdcdcd;
          border: 1px solid #cdcdcd;
        }
      }

      .truncate {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    `}
  />
);

const ThemeWrapper: React.FC = ({ children, ...props }) => {
  return (
    <ThemeProvider theme={theme} {...props}>
      <GlobalStyle />
      {children}
    </ThemeProvider>
  );
};

export default ThemeWrapper;
