/* eslint-disable max-len */
const { info } = require('./sitedata');

exports.IMAGE_SIZES = {
  xl: '1200x800',
  large: '728x464',
  medium: '320x240',
  small: '72x72'
};

exports.LOCAL_STORAGE = {
  VERSION_STORAGE_KEY: 'version',
  AUTH_STORAGE_KEY: 'authData',
  PROFILE_STORAGE_KEY: 'profileData',
  SAVED_ITEMS_STORAGE_KEY: 'savedItemsData'
};

exports.SITE_URL = info.siteUrl;

exports.REGEXP = {
  EMAIL: /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@([\w-]+\.)+[\w-]{2,}$/,
  PASSWORD: /^[A-Za-z\d{}()[\]#:;^,.?!|&_`~@$%/\\=+\-*"']{8,32}$/,
  ZIP_CODE: /(?!00[02-5]|099|213|269|34[358]|353|419|42[89]|51[789]|529|53[36]|552|5[67]8|5[78]9|621|6[348]2|6[46]3|659|69[4-9]|7[034]2|709|715|771|81[789]|8[3469]9|8[4568]8|8[6-9]6|8[68]7|9[02]9|987)\d{5}/,
  NAME: /^[\w\u0100-\u017F\u0180-\u024F\u0080-\u00FF ,.'-][^0-9]+$/,
  MLS_ID: /\b[A-Z]{2,3}\w+\b/
};

exports.STATES = {
  AL: 'Alabama',
  AK: 'Alaska',
  AZ: 'Arizona',
  AR: 'Arkansas',
  CA: 'California',
  CO: 'Colorado',
  CT: 'Connecticut',
  DE: 'Delaware',
  DC: 'District Of Columbia',
  FL: 'Florida',
  GA: 'Georgia',
  HI: 'Hawaii',
  ID: 'Idaho',
  IL: 'Illinois',
  IN: 'Indiana',
  IA: 'Iowa',
  KS: 'Kansas',
  KY: 'Kentucky',
  LA: 'Louisiana',
  ME: 'Maine',
  MD: 'Maryland',
  MA: 'Massachusetts',
  MI: 'Michigan',
  MN: 'Minnesota',
  MS: 'Mississippi',
  MO: 'Missouri',
  MT: 'Montana',
  NE: 'Nebraska',
  NV: 'Nevada',
  NH: 'New Hampshire',
  NJ: 'New Jersey',
  NM: 'New Mexico',
  NY: 'New York',
  NC: 'North Carolina',
  ND: 'North Dakota',
  OH: 'Ohio',
  OK: 'Oklahoma',
  OR: 'Oregon',
  PA: 'Pennsylvania',
  RI: 'Rhode Island',
  SC: 'South Carolina',
  SD: 'South Dakota',
  TN: 'Tennessee',
  TX: 'Texas',
  UT: 'Utah',
  VT: 'Vermont',
  VA: 'Virginia',
  WA: 'Washington',
  WV: 'West Virginia',
  WI: 'Wisconsin',
  WY: 'Wyoming'
};
