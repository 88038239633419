import React from 'react';

import styled from '@emotion/styled';

interface NotificationProps extends React.HTMLAttributes<HTMLDivElement> {
  type?: 'success' | 'error' | 'default';
  onClose?: () => void;
}

const Notification: React.FC<NotificationProps> = ({ type, children, onClose }) => {
  return (
    <StyledNotification className={type} onClick={onClose}>
      {children}
    </StyledNotification>
  );
};

export default Notification;

const StyledNotification = styled.div`
  display: flex;
  box-sizing: border-box;
  padding: 10px;
  align-items: center;
  background: #000000c4;
  color: #fff;
  fill: #fff;
  box-shadow: 0px 18px 50px rgba(0, 0, 0, 0.16);
  width: 464px;
  min-height: 36px;
  max-width: 100vw;
  font-weight: bold;
  font-size: 12px;
  line-height: 16px;
  border-radius: 3px;
  margin-top: 8px;

  &.error {
    background: #ff7272;
    font-weight: 500;
    .icon {
      fill: #fff;
    }
  }
`;
